import type { UseFetchOptions } from 'nuxt/app';
import { defu } from 'defu';

export function useApiFetch<T>(url: string, options: UseFetchOptions<T> = {}) {
  const config = useRuntimeConfig();
  const nuxtApp = useNuxtApp();
  const regionCode = useRegionCode();

  const baseURL = config.public.apiUrl[regionCode];

  // FIXME:
  // const httpsAgent =
  //   process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local'
  //     ? new https.Agent({
  //         rejectUnauthorized: false,
  //       })
  //     : null;

  const defaults: UseFetchOptions<T> = {
    baseURL,

    key: baseURL + url,

    credentials: 'include',

    onResponseError: (ctx) => {
      nuxtApp.$toast.clear();
      nuxtApp.$toast.error(
        // eslint-disable-next-line no-underscore-dangle
        ctx?.response?._data?.clientMessage ||
          nuxtApp.$i18n.t('error.something_went_wrong')
      );
    },
  };

  const params = defu(options, defaults);

  return useFetch(url, params);
}
